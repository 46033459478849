<template>
    <investor-projects-component/>
</template>

<script>
import InvestorProjectsComponent from "@/components/investors/InvestorProjectsComponent";
export default {
    name: "InvestorProjectsView",
    components: {InvestorProjectsComponent}
}
</script>

<style scoped>

</style>