<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                :headers="headers"
                :items="projects"
                :items-per-page="10"
                :search="searchProject"
                :loading="loadingData"
                :expanded="expanded"
                single-expand
                show-expand
                item-key="id"
                sort-by="id"
                disable-sort
                no-data-text="No hay datos para mostrar..."
                loading-text="Cargando los datos, por favor espere."
                class="elevation-1"
                :footer-props="{
                        showFirstLastPage: true,
                        disableItemsPerPage: false,
                        itemsPerPageAllText: 'Todas',
                        itemsPerPageText: 'Filas por página:'
                    }">
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1 text-md-h5' : ''">
                            <span>Asignaciones de Proyectos</span>
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                id="findtext-project"
                                label="Buscar..."
                                name="findproject"
                                outlined
                                dense
                                single-line
                                clearable
                                background-color="white"
                                hide-details
                                v-model="searchProject"
                                class="white--text mt-0 pt-0 mr-0"
                                prepend-inner-icon="mdi-magnify"
                            ></v-text-field>
                        </v-responsive>
                        <v-btn @click="getProjects"
                               :loading="loadingData"
                               elevation="0"
                               :small="$vuetify.breakpoint.smAndDown"
                               class="mb-1 ml-2"
                        >
                          <v-icon>mdi-reload</v-icon>
                        </v-btn>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogDocumentsPDF"
                            persistent
                            width="600">
                            <v-card>
                                <v-toolbar dark color="primary" height="60">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="dialogDocumentsPDF = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Documentos PDF</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="dialogDocumentsPDF = false">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <v-list dense subheader>
                                                    <v-subheader>Lista de Archivos</v-subheader>
                                                    <v-divider/>
                                                    <v-list-item
                                                        v-for="doc in project_documents"
                                                        :key="doc.id">
                                                        <v-list-item-icon>
                                                            <v-icon color="primary">
                                                                mdi-file-document
                                                            </v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                                v-text="doc.name"></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-tooltip bottom v-if="doc.url != null">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        dark
                                                                        icon
                                                                        small
                                                                        color="orange"
                                                                        class="ma-0 text--darken-1"
                                                                        elevation="0"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        v-on:click="openPDF(doc)">
                                                                        <v-icon>mdi-magnify</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Ver documento</span>
                                                            </v-tooltip>
                                                        </v-list-item-icon>
                                                    </v-list-item>
                                                    <v-list-item v-if="project_documents.length === 0">
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                <span class="text-h5">
                                                                    No contiene documento
                                                                </span>
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogEvents"
                            persistent
                            width="1000">
                            <v-card>
                                <v-toolbar dark color="primary" height="60">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="dialogEvents = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Asignaciones de Eventos</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="dialogEvents = false">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <v-simple-table
                                                    fixed-header
                                                    height="300px">
                                                    <template v-slot:default>
                                                        <thead>
                                                        <tr>
                                                            <th class="text-left">
                                                            </th>
                                                            <th class="text-left">
                                                                Nombre del Evento
                                                            </th>
                                                            <th class="text-left">
                                                                Fecha del Evento
                                                            </th>
                                                            <th class="text-left">
                                                                Monto de Inversi&oacute;n
                                                            </th>
                                                            <th class="text-left">
                                                                Ingreso del Evento
                                                            </th>
                                                            <th class="text-left">
                                                                Gasto del Evento
                                                            </th>
                                                            <th class="text-left">
                                                                Utilidad
                                                            </th>
                                                            <th class="text-left" v-if="true">
                                                                Acciones
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr
                                                            v-for="event in project_events"
                                                            :key="event.id">
                                                            <td>
                                                                <v-avatar color="#ded06f" size="40" class="my-1">
                                                                    <v-img
                                                                        :src="(event.image_url !== null ||
                                                                        event.image_url !== '') ?
                                                                        event.image_url :
                                                                        require('@/assets/images/no-image.jpg')">
                                                                    </v-img>
                                                                </v-avatar>
                                                            </td>
                                                            <td>{{ event.name }}</td>
                                                            <td>{{ formatDate(event.start_date) }}</td>
                                                            <td>{{ event.investment_amount | toCurrency }}</td>
                                                            <td>{{ event.investment_income | toCurrency }}</td>
                                                            <td>{{ event.expense | toCurrency }}</td>
                                                            <td>{{ event.utility | toCurrency }}</td>
                                                            <td v-if="false">
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            dark
                                                                            icon
                                                                            color="primary"
                                                                            class="ma-0 darken-1"
                                                                            elevation="0"
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            v-on:click="viewEvent(event)">
                                                                            <v-icon>mdi-magnify</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Ver detalles del evento</span>
                                                                </v-tooltip>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </template>
                                                </v-simple-table>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogChartProject"
                            persistent
                            width="700">
                            <v-card>
                                <v-toolbar dark color="primary" height="60">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="dialogChartProject = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Gr&aacute;fica de Proyectos</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="dialogChartProject = false">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height" v-if="eventsProject.length > 0">
                                                <div class="container--fluid fill-height" style="width:100%;">
                                                    <VueApexCharts
                                                        ref="chartProjects"
                                                        height="400" type="line"
                                                        :options="chartProjectOptions"
                                                        :series="seriesProject"/>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="container--fluid fill-height" style="width:100%;">
                                                <span class="text-h5 text-center">
                                                    Este proyecto no contiene eventos asociados.
                                                </span>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.active="{ item }">
                    <v-simple-checkbox
                        v-model="item.active"
                        disabled
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.image_url="{ item }">
                    <v-avatar color="#ded06f" size="40">
                        <v-img
                            :src="item.pictures.length > 0 ? item.pictures[0].url :
                            require('@/assets/images/no-image.jpg')">
                        </v-img>
                    </v-avatar>
                </template>
                <template v-slot:item.start_date="{ item }">
                    <span>{{ formatDate(item.start_date) }}</span>
                </template>
                <template #item.amount="{ item }">
                    <span class="text-caption">
                        {{ item.pivot.investment_amount | toCurrency }} mxn
                    </span>
                </template>
                <template #item.income="{ item }">
                    <span class="text-caption">
                        {{ item.pivot.investment_income | toCurrency }} mxn
                    </span>
                </template>
                <template #item.expense="{ item }">
                    <span class="text-caption">
                        {{ item.pivot.expense | toCurrency }} mxn
                    </span>
                </template>
                <template #item.revuene="{ item }">
                    <span class="text-caption">
                        {{ item.pivot.utility | toCurrency }} mxn
                    </span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="loadListEvents(item)">
                                mdi-calendar-clock
                            </v-icon>
                        </template>
                        <span>Mostrar Eventos</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="loadListDocuments(item)">
                                mdi-file-document
                            </v-icon>
                        </template>
                        <span>Mostrar Documentos PDF</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                color="black"
                                small
                                class="mr-2"
                                @click="showChartProject(item)">
                                mdi-chart-areaspline
                            </v-icon>
                        </template>
                        <span>Gr&aacute;fica del Proyecto</span>
                    </v-tooltip>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-container fluid>
                            <v-row justify="start" class="mx-1 mt-1 mb-0">
                                <h3>{{ item.name }}</h3>
                            </v-row>
                            <v-row class="ma-1">
                                <v-col cols="12" md="8">
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Descripci&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.description }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Ubicaci&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.location }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Fecha de inicio:
                                        </v-col>
                                        <v-col>
                                            {{ formatDate(item.start_date) }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="false">
                                        <v-col cols="12" md="4">
                                            Fecha estimada de terminaci&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ formatDate(item.finish_date) }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="true">
                                        <v-col cols="12" md="4">
                                            Inversi&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.pivot.investment_amount | toCurrency}} mxn
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="true">
                                        <v-col cols="12" md="4">
                                            Ingresos:
                                        </v-col>
                                        <v-col>
                                            {{ item.pivot.investment_income | toCurrency }} mxn
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="true">
                                        <v-col cols="12" md="4">
                                            Retorno de inversi&oacute;n esperado:
                                        </v-col>
                                        <v-col>
                                            {{ item.pivot.expense | toCurrency }} mxn
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="false">
                                        <v-col cols="12" md="4">
                                            Canal de Youtube:
                                        </v-col>
                                        <v-col>
                                            <span v-if="item.youtube_video !== null">
                                                {{ item.youtube_video }}
                                            </span>
                                            <span v-else>
                                                (ninguno)
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="false">
                                        <v-col cols="12" md="4">
                                            Estado del proyecto:
                                        </v-col>
                                        <v-col>
                                            <span v-if="item.status === 'started'">
                                                Iniciado
                                            </span>
                                            <span v-else-if="item.status === 'execution'">
                                                En ejecuci&oacute;n
                                            </span>
                                            <span v-else>
                                                Terminado
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-row justify="center">
                                        <v-img
                                            :src="item.pictures[0] != null ? item.pictures[0].url : require('@/assets/images/no-image.jpg')"
                                            :lazy-src="require('@/assets/images/no-image.jpg')"
                                            height="100%"
                                            width="100%"
                                            aspect-ratio="1"
                                            class="white--text align-center justify-center elevation-2">
                                        </v-img>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row v-if="false">
                                <v-col cols="12" md="6">
                                    <div class="fill-height mt-0">
                                        <v-expansion-panels tile flat accordion hover>
                                            <v-expansion-panel>
                                                <v-expansion-panel-header>
                                                    Eventos del Proyecto
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-list dense subheader>
<!--                                                        <v-subheader>Lista de Archivos</v-subheader>-->
                                                        <v-divider/>
                                                        <v-list-item
                                                            v-for="event in item.events"
                                                            :key="event.id">
                                                            <v-list-item-icon>
                                                                <v-icon color="orange">
                                                                    mdi-calendar-clock
                                                                </v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-text="event.name"></v-list-item-title>
                                                            </v-list-item-content>
                                                            <v-list-item-icon>
                                                                <v-tooltip bottom>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            dark
                                                                            icon
                                                                            small
                                                                            color="black"
                                                                            class="ma-0"
                                                                            elevation="0"
                                                                            v-bind="attrs"
                                                                            v-on="on">
                                                                            <v-icon>mdi-chevron-down</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Ver detalles del evento</span>
                                                                </v-tooltip>
                                                            </v-list-item-icon>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <div class="fill-height mt-0">
                                        <v-expansion-panels tile flat accordion hover>
                                            <v-expansion-panel>
                                                <v-expansion-panel-header>
                                                    Documentos PDF
                                                </v-expansion-panel-header>
                                                <v-expansion-panel-content>
                                                    <v-list dense subheader>
                                                        <v-subheader>Lista de Archivos</v-subheader>
                                                        <v-divider/>
                                                        <v-list-item
                                                            v-for="document in item.projects_documents"
                                                            :key="document.id">
                                                            <v-list-item-icon>
                                                                <v-icon color="orange">
                                                                    mdi-file-document
                                                                </v-icon>
                                                            </v-list-item-icon>
                                                            <v-list-item-content>
                                                                <v-list-item-title v-text="document.name"></v-list-item-title>
                                                            </v-list-item-content>
                                                            <v-list-item-icon>
                                                                <v-tooltip bottom v-if="document.url != null">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            dark
                                                                            icon
                                                                            small
                                                                            color="orange"
                                                                            class="ma-0 text--darken-1"
                                                                            elevation="0"
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            v-on:click="openPDF(document)">
                                                                            <v-icon>mdi-magnify</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Ver documento</span>
                                                                </v-tooltip>
                                                            </v-list-item-icon>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-expansion-panel-content>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </td>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import moment from "moment";
import investorService from '@/providers/InvestorService';
import VueApexCharts from "vue-apexcharts";
// import ProjectChartSettings from "./ProjectChartSettings";

function formatToUnits(number, precision) {
    const abbrev = ['', ' K', ' MDP', ' B', ' T'];
    const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3)
    const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length -1 ))
    const suffix = abbrev[order];

    return (number / Math.pow(10, order * 3)).toFixed(precision) + suffix;
}

export default {
    props: {
        height: {
            type: String,
            default: "400px",
        },
        color: {
            type: String,
            default: "orange",
        },
        containerID: {
            type: String,
            default: "map",
        }
    },
    name: "InvestorProjectsComponent",
    components: { VueApexCharts },
    data: () => ({
        selectedEvent: null,
        dialogDocumentsPDF: false,
        dialogEvents: false,
        dialogChartProject: false,
        searchProject: "",
        loadingData: false,
        investor: null,
        projects: [],
        project_events: [],
        project_documents: [],
        events: [],
        investor_id: 0,
        dataDetailReportProjectInvestor: null,
        eventsProject : [],
        expanded: [],
        // projectChartSettings: ProjectChartSettings,
        seriesProject: [
            {
                name: 'Ingresos',
                type: 'line',
                data: []
            },{
                name: 'Utilidad',
                type: 'line',
                data: []
            }
        ],			
        chartProjectOptions: {
            chart: { 
                id: 'chart-projects',
                height: 350,
                width: '100%',
                type: 'line',
                stacked: false,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                }
            },
            stroke: {
                width: [1, 4, 4, 4]
            },
            markers: {
                size: 5,
                strokeColors: "transparent",
            },
            grid: {
                borderColor: "rgba(0, 0, 0, .1)",
                show: true,
                enabled: true,
                xaxis: {
                    lines: {
                        show: false,
                    },
                },
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
            // colors: ['#387df3', '#de373d', '#8000FF', '#aade87'],
            colors: ['#4989f6',
                //'#8000FF',
                '#079a67',
                '#88c75e'
            ],
            title: {
                text: '',
                align: 'center',
                style: {
                    color: '#444'
                }
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    console.log('series:', series);
                    console.log('seriesIndex:', seriesIndex);
                    let data = w.globals.initialSeries[0].data[dataPointIndex];
                    return '<v-card elevation="2" outlined class="ma-3 caption transparent text-left justify-start">' +
                        '<table cellpadding="3" cellspacing="3" class="transparent">' +
                            '<tr class="text-left">' +
                                '<th>Evento: </th>' +
                                '<th>' +
                                    '<v-card-title class="grey--text text--darken-3">'+data.full_label+'</v-card-title>' +
                                '</th>' +
                            '</tr>' +
                        '<tr class="card-header" style="color: #4989f6;">' +
                        '<th>Crecimiento: </th>' +
                        '<td>' +
                            '<v-card-title class="grey--text text--darken-3">'+
                                '<span style="color: #4989f6;">' +
                                    '$ '+formatToUnits(data.y, 2)+'</v-card-title>' +
                                '</span>'+
                            '</td>' +
                        '</tr>' +
                            '<tr class="card-header" style="color: #8000FF;">' +
                                '<th>Ingresos: </th>' +
                                '<td>' +
                                    '<v-card-title class="grey--text text--darken-3">'+
                                    '    <span style="color: #8000FF;">'+
                                        '$ '+formatToUnits(data.income, 2)+
                                    '   </span> '+
                                    ' </v-card-title>' +
                                '</td>' +
                            '</tr>' +
                            '<tr class="card-header" style="color: #079a67;">' +
                                '<th>Utilidad: </th>' +
                                '<td>' +
                                    '<v-card-title class="grey--text text--darken-3">'+
                                        '<span style="color: #079a67;">' +
                                            '$ '+formatToUnits(data.utility, 2)+
                                        '</span>'+
                                    '</v-card-title> ' +
                                '</td>' +
                            '</tr>' +
                        '</table>' +
                        '</v-card>';
                }
            },
            dataLabels: {
                enabled: false,
                enabledOnSeries: [2],
                formatter: function (value) {
                    // return formatToUnits(value, 0);
                    return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                }
            },
            labels: [],
            legend: {
                position: 'top',
            },
            xaxis: {
                type: 'string',
                lines: {
                    show: true,
                },
                categories: [],
                labels: {
                    show: true,
                    style: {
                        cssClass: "grey--text text--lighten-2 fill-color",
                    }
                },
            },
            yaxis: [
                {
                    seriesName: "Eventos",
                    show: true,
                    type: "numeric",
                    lines: {
                        show: true,
                    },
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#dedee0'
                    },
                    labels: {
                        style: {
                            colors: '#4989f6',
                        },
                        formatter: function (value) {
                            return '$ '+formatToUnits(value, 2);
                            // return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                        }
                    },
                    title: {
                        text: 'Eventos',
                        style: {
                            color: '#4989f6',
                        }
                    },
                    tooltip: {
                        enabled: false
                    },
                },{
                    seriesName: "Ingresos",
                    show: true,
                    type: "numeric",
                    opposite: true,
                    lines: {
                        show: true,
                    },
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#05754e'
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: '#05754e',
                        },
                        formatter: function (value) {
                            return '$ '+formatToUnits(value, 2);
                            // return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                        }
                    },
                    title: {
                        text: 'Utilidad o Pérdida',
                        style: {
                            color: '#05754e',
                        }
                    },
                },{
                    seriesName: "Ingresos",
                    type: "numeric",
                    show: false,
                    opposite: true,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#00E396'
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: '#05754e',
                        },
                        formatter: function (value) {
                            return value.toLocaleString("en-US",{style:"currency", currency:"USD"});
                        }
                    },
                    title: {
                        text: 'Utilidad/Pérdida',
                        style: {
                            color: '#05754e',
                        }
                    },
                },{
                    seriesName: "Lineal",
                    logarithmic: false,
                    tickAmount: 4,
                    axisTicks: {
                        show: false,
                    },
                    axisBorder: {
                        show: false
                    },
                    labels: {
                        show:false,
                    },
                    title: {
                        text: "",
                    },
                    tooltip: {
                        enabled: false
                    }
                }
            ]
        },
        headers: [
            {text: '', value: 'data-table-expand', width: 5},
            {text: '', value: 'image_url', width: 40},
            {text: "Id", value: "id", align: " d-none"}, // para ocultar la columna Id.
            {
                text: "Nombre del Proyecto",
                align: "start",
                sortable: false,
                value: "name",
                width: 300
            },
            {text: "Fecha del Evento", value: "start_date"},
            {text: "Monto de Inversión", value: "amount", width: 145, align: "right"},
            {text: "Ingresos Estimados", value: "income", width: 145, align: "right"},
            {text: "Gastos Planificados", value: "expense", width: 145, align: "right"},
            {text: "Utilidad Esperada", value: "revuene", width: 145, align: "right"},
            {text: "Acciones", value: "actions", width: 110, align: "center", sortable: false}
        ],
        archiveItemId: undefined,
    }),
    filters: {
        formatTheDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/yyyy');
            }
        },
        toCurrency (value) {
            if (typeof value !== "number") {
                return value;
            }
            let formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                useGrouping: true,
                minimumFractionDigits: 2
            });
            return formatter.format(value);
        },
        toTwoDecimal (value) {
            if (typeof value !== "number") {
                return value;
            }
            let formatter = new Intl.NumberFormat('en-US', {
                useGrouping: false,
                maximumSignificantDigits: 2,
                minimumFractionDigits: 2
            });
            return formatter.format(value);
        }
    },
    computed: {
        computedStartDateFormatted() {
            return this.editedItem.start_date ? this.formatDate(this.editedItem.start_date) : '';
        },
        computedFinishDateFormatted() {
            return this.editedItem.finish_date ? this.formatDate(this.editedItem.finish_date) : '';
        },
        containerCSS() {
            return {
                width: "100%",
                height: this.height,
            };
        }
    },
    methods: {
        formatDate(value) {
            if (!value) return null;
            let theDate = value.toString().substring(0, 10);
            const [year, month, day] = theDate.split('-')
            return `${day}/${month}/${year}`;
        },
        parseDate(date) {
            if (!date) return null;
            let theDate = date.toString().substring(0, 10);
            const [month, day, year] = theDate.split('/');
            return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
        },
        getDetailReport(project_id, investor_id) {
            investorService.getReportDetailProjectsInvestor(project_id, investor_id).then(record => {
                if (record.success) {
                    this.dataDetailReportProjectInvestor = record.data;
                    this.chartProjectOptions.title.text =
                        this.dataDetailReportProjectInvestor.project_name;
                    this.seriesProject[0].data = [];
                    this.seriesProject[1].data = [];
                    this.chartProjectOptions.labels = [];
                    let growValues = [];
                    let incomeValues = [];
                    let revueneValues = [];
                    let expensesValue = [];
                    let linealValues = [];
                    let linealData = [];
                    let labels = [];
                    this.eventsProject = this.dataDetailReportProjectInvestor.events;
                    this.eventsProject.forEach(data => {
                        // Crecimiento
                        let growValue = {
                            // x : data.event_name.split(' ').length>3 ? data.event_name.split(" ") : data.event_name,
                            x : data.event_name.split(" "),
                            y : data.capital_grow_event,
                            full_label: data.event_name,
                            income: data.investment_income_event,
                            expense: data.expense_event,
                            utility: data.utility_event,
                        };
                        growValues.push(growValue);
                        // Ingresos
                        let incomeValue = {
                            x : data.event_index_name,
                            y : data.investment_income_event,
                            full_label: data.event_name
                        };
                        incomeValues.push(incomeValue);
                        // Gastos
                        let expenseValue = {
                            x : data.event_index_name,
                            y : data.expense_event,
                            full_label: data.event_name
                        }
                        expensesValue.push(expenseValue);
                        // Utilidad
                        let revenueValue = {
                            x : data.event_index_name,
                            y : data.utility_event,
                            full_label: data.event_name
                        };
                        revueneValues.push(revenueValue);
                        // Crecimiento Lineal
                        let linealValue = {
                            x : '',
                            y : data.capital_grow_event,
                            full_label: data.event_name
                        };
                        linealData.push(data.capital_grow_event);
                        linealValues.push(linealValue);
                        labels.push(data.event_index_name); //
                    });
                    this.seriesProject = [
                        {
                            name: 'Crecimiento',
                            type: 'column',
                            data: growValues
                        }/*,{
                            name: 'Ingresos',
                            type: 'line',
                            data: incomeValues
                        }*/,{
                            name: 'Utilidad/Pérdida',
                            type: 'line',
                            data: revueneValues
                        }
                    ]
                    this.chartProjectOptions.xaxis.categories = labels;
                    if (this.eventsProject.length > 0) {
                        this.$refs.chartProjects.refresh();
                    }
                } else {
                    this.dataDetailReportProjectInvestor = null;
                    this.eventsProject = [];
                }
            }).catch(err => {
                console.log("Error: ", err);
            });
        },
        getProjects() {
            this.loadingData = true;
            // Catalogo de Proyectos...
            investorService.getListProjectsEvents(this.investor_id).then(record => {
                this.investor = record.value;
                this.projects = this.investor.projects;
                this.events = this.investor.events;
            }).catch(err => {
                console.log("Error: ", err);
            }).finally(() =>{ this.loadingData = false; });
        },
        loadListDocuments(item) {
            // Carga la lista de documentos PDF...
            this.project_documents = Object.assign({}, item.projects_documents);
            if (!this.dialogDocumentsPDF) this.dialogDocumentsPDF = true;
        },
        openPDF(document) {
            //  let baseURL = this.$store.getters.getBaseURL;
            let urlPdf = document.url; // `${baseURL}/view-pdf`;
            window.open(urlPdf, "_blank");
        },
        loadListEvents(item) {
            console.log('item: ', item);
            this.selectedEvent = null;
            this.$router.push({
                name: "InvestorEvents",
                params: {
                    id: item.id
                }
            });
            // this.project_events = Object.assign({}, item.events);
            // if (!this.dialogEvents) this.dialogEvents = true;
        },
        showChartProject(item) {
            this.getDetailReport(item.id, item.pivot.investor_id);
            if (!this.dialogChartProject) this.dialogChartProject = true;
        }
    },
    mounted() {
        this.projects = [];
        let sessionInvestor = Object.assign({}, JSON.parse(sessionStorage.getItem('investor')));
        if (sessionInvestor !== null) {
            this.investor_id = sessionInvestor.user.investors[0].id;
            this.getProjects();
        } else {
            this.$router.push({ name: "InvestorLogin" });
        }
    }
}
</script>

<style scoped>

</style>